export const CONTACT_URL_BASE = 'https://fitsplus.co.jp/contact';
export const CONTACT_URL = `${CONTACT_URL_BASE}?${new URLSearchParams({
  from: 'dietplusjp',
}).toString()}`;
export const CONTACT_URL_WITH_MAIL_PARAMS = `${CONTACT_URL_BASE}?${new URLSearchParams(
  {
    from: 'dietplusjp',
    utm_source: 'mailmaga',
    utm_medium: 'email',
    utm_content: '01',
    utm_campaign: 'stepmail',
  },
).toString()}`;
